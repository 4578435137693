<template>
	<div class="main" :class="show ? 'h5main' : ''">
		<mtop />
		<div class="demo_top" id="top"></div>
		<div class="demo_box1">
			<div class="box1_top">
				<div class="img"></div>
				<div>
					您的当前位置： 网站首页 >
					<span @click="toPage('/newsCenter')">新闻中心 </span>>新闻详情
				</div>
			</div>
			<div class="box1_bottom">
				<div class="bottom_left">
					<div class="left_title">{{ actInfo.title }}</div>

					<div class="left_time">
						发表日期：{{ actInfo.create_time }} 作者：{{
							actInfo.author
						}}
						浏览：{{ actInfo.click }}
					</div>

					<div class="left_con" v-html="actInfo.content"></div>

					<div class="left_tip">
						声明：如没特殊注明，文章均为星润科技原创，转载请注明
					</div>
				</div>
				<div class="bottom_right">
					<div
						class="right_item"
						v-for="(value, key) in rightList"
						:key="key"
					>
						<div class="item_title">{{ key }}</div>
						<div
							class="item_item"
							v-for="(item, index) in rightList[key]"
							:key="index"
							@click="getMes(item.id)"
						>
							<div class="item_con">{{ item.title }}</div>
							<div class="item_date">
								日期：{{ item.create_time }} 浏览：{{
									item.click
								}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<mbottom />
	</div>
</template>

<script>
	import { connectTron, walletOfOwner, tokenURI } from "@/api/Public";
	import mtop from "../components/top.vue";
	import mbottom from "../components/bottom.vue";
	export default {
		data() {
			return {
				actInfo: {},
				rightkey: [],
				rightValue: [],
				rightList: {},
				id: null,
				list: [1, 2, 3, 4, 5, 6],
				active: "",
				show: false,
				name: "",
				formdata: {
					Page: 1,
					PageSize: "20",
				},
				menu: "",
				loading: false,
				finished: false,
				refreshing: false,
				showlist: false,
				mylist: {},
				basemenu: "",
			};
		},
		components: {
			mtop,
			mbottom,
		},
		mounted() {
			if (this._isMobile()) {
				this.show = true;
			} else {
				this.show = this.$store.getters.phone;
			}
		},
		watch: {
			"$store.getters.conid": function (val) {
				sessionStorage.setItem("conid", val);
				console.log(val);
			},
			"$store.getters.phone": function (val) {
				this.show = val;
			},
			deep: true,
			immediate: true,
		},
		created() {
			// this.getMenu();
			if (this.$route.params.detail) {
				this.id = this.$route.params.detail.id;
				this.getInfo();
				console.log(this.id);
			} else {
				this.id = sessionStorage.getItem("conid");
				this.getInfo();
			}
		},
		methods: {
			toPage(e) {
				this.$router.replace({ path: e });
			},
			getMes(val) {
				this.id = val;
				sessionStorage.setItem("conid", val);
				this.getInfo();
				this.$nextTick(() => {
					document.getElementById("top").scrollIntoView({
						behavior: "smooth",
						block: "start",
						inline: "nearest",
					});
				});
			},
			getInfo() {
				this.$axios({
					method: "get",
					url: "/v1/articleRead",
					params: { id: this.id },
				})
					.then((res) => {
						console.log(res);
						this.actInfo = res.data.data.info;
						this.rightList = res.data.data.list;
						console.log(res.data.data.info, this.rightList);
						this.rightkey = [];
						this.rightValue = [];
						for (var key in this.rightList) {
							this.rightkey.push(key);
							this.rightValue.push(this.rightList[key]); //取得value
							console.log(this.rightValue);
						}
						console.log(this.rightkey, this.rightValue);
					})
					.catch(() => {
						console.log("请求失败");
					});
			},
			wallet() {
				var i = 0;
				var a = setInterval(() => {
					if (typeof window.ethereum !== "undefined") {
						connectTron().then(
							(info) => {
								clearInterval(a);
								if (info[0].indexOf("0x") == 0) {
									this.$store.commit("userWallet", info[0]);
								} else {
									this.$toast(
										"Use the ETH address to log in to the game"
									);
								}
							},
							(e) => {
								this.$toast(e.message);
							}
						);
					}
					if (i > 5) {
						clearInterval(a);
					}
					i++;
				}, 1000);
			},
			seach() {
				this.formdata = {
					Name: this.name,
					Page: 1,
					PageSize: "20",
				};
				this.menu = this.basemenu;
				this.$forceUpdate();
				this.finished = false;
				this.loading = true;
				this.onLoad();
			},
			tochange(e, j, i) {
				this.menu[i].list.forEach((el) => {
					if (j == el.name) {
						el.show = true;
					} else {
						el.show = false;
					}
				});
				this.$set(this.menu[i], "child", j);
				console.log(e, j);
				if (JSON.stringify(this.formdata).indexOf(e) != -1) {
					this.formdata[e] = j;
				} else {
					var a = { [e]: j };
					this.formdata = Object.assign(a, this.formdata);
				}
				this.onRefresh();
			},
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
			res() {
				this.formdata = {
					Page: 1,
					PageSize: 20,
				};
				this.name = "";
				// this.getMenu();
				this.onRefresh();
			},
			goopen(e) {
				let url =
					"https://opensea.io/assets/matic/0xb5e200d739bc792e273b2beb6dea9fd918ede79b/" +
					e;
				window.open(url);
			},
			onRefresh() {
				this.formdata.Page = 1;
				this.finished = false;
				this.loading = true;
				this.onLoad();
			},
			onLoad() {
				if (this.formdata.Page == 1) {
					this.list = [];
					this.refreshing = false;
				}
				this.$get({
					url: "cupid",
					data: this.formdata,
					callback: (res) => {
						this.$nextTick(function () {
							if (res.data) {
								let reg = new RegExp("ipfs://", "g");
								var list = res.data.items.map((el) => {
									el.url = el.image.replace(
										reg,
										"https://www.cupidlovemetaverse.com/ipfs/"
									);
									return el;
								});

								this.loading = false;
								if (this.formdata.Page > 1) {
									this.list = [...this.list, ...list];
								} else {
									this.list = list;
								}
								// console.log(this.list);

								if (res.data.items.length == 20) {
									this.formdata.Page++;
								} else {
									this.finished = true;
								}
							}
						});
					},
				});
			},
			getMenu() {
				this.$get({
					url: "menu",
					data: {},
					callback: (res) => {
						this.$nextTick(function () {
							if (res.data) {
								this.menu = res.data;
								this.basemenu = res.data;
							}
						});
					},
				});
			},
			tochild(e, s) {
				this.menu.forEach((el, i) => {
					if (e == i) {
						el.show = !s;
					} else {
						el.show = false;
					}
				});
				this.$forceUpdate();

				// this.$set(this.menu[e],"show",!s)
			},
			toshowList() {
				var address = this.$store.getters.userWallet;
				if (!address) {
					this.wallet();
					return;
				}
				walletOfOwner(this.$store.getters.userWallet).then((res) => {
					// console.log("res",res);
					this.basedata = res;
					if (res.length > 0) {
						this.showlist = true;
						res.forEach((el) => {
							this.getUrl(el);
						});
						// this.list.length=res.length
						// console.log(this.list);
					}
				});
			},
			closes() {
				this.showlist = false;
			},
			getUrl(e) {
				tokenURI(e).then((res) => {
					let regs = new RegExp("https://ipfs.io/", "g");
					var urls = res.replace(
						regs,
						"https://www.cupidlovemetaverse.com/"
					);
					this.$axios({
						method: "GET",
						url: urls,
					}).then((response) => {
						// console.log(response.data);
						let reg = new RegExp("ipfs://", "g");
						var url = response.data.image.replace(
							reg,
							"https://www.cupidlovemetaverse.com/ipfs/"
						);
						this.mylist[e] = url;

						this.$forceUpdate();
					});
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	$t-mf: "Arial";

	.el-icon-arrow-down:before {
		content: "";
	}
	.main {
		background: #f7f7f7;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 100vh;
		width: 100%;
		min-width: 1200px;
		max-width: 100%;
		.demo_top {
			width: 100%;
			height: 85px;
			background: #000000;
		}
		.demo_box1 {
			width: 1200px;
			margin: 0 auto;
			.box1_top {
				display: flex;
				text-align: left;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #999999;
				line-height: 50px;

				align-items: center;
				.img {
					width: 24px;
					height: 24px;
					background: url("../assets/indeximg/point.png");
					background-size: 100% 100%;
					margin-right: 12px;
				}
				div > span {
					color: #0052da;
					cursor: pointer;
				}
			}
			.box1_bottom {
				width: 1200px;
				min-height: 100vh;

				border: 1px solid #f3f3f3;
				margin: 0 auto;
				display: flex;
				box-sizing: border-box;
				justify-content: space-between;
				// align-items: center;
				.bottom_left {
					width: 824px;
					// height: 1429px;
					min-height: 360px;
					background: #ffffff;
					box-sizing: border-box;
					padding: 40px 30px;
					overflow: auto;
					.left_title {
						font-size: 28px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						text-align: center;
					}
					.left_time {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #999999;
						text-align: left;
						line-height: 60px;
					}
					.left_con {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						text-align: left;
						margin: 30px 0px;
					}
					.left_tip {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #999999;
						line-height: 30px;
						text-align: left;
					}
				}
				.bottom_right {
					width: 362px;
					// height: 1429px;
					min-height: 360px;
					background: #ffffff;
					box-sizing: border-box;
					padding: 30px 20px;
					.right_item {
						width: 315px;
						max-height: 284px;

						.item_title {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #999999;
							line-height: 30px;
							text-align: left;
							margin-top: 10px;
						}
						.item_item {
							border-bottom: 1px solid #e6e6e6;
							margin-bottom: 10px;
							.item_con {
								font-size: 14px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								line-height: 30px;
								text-align: left;
								cursor: pointer;
							}
							.item_con:hover {
								color: #0052da;
							}
							.item_date {
								font-size: 10px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #999999;
								line-height: 30px;
								text-align: left;
								line-height: 45px;
							}
						}
					}
				}
			}
		}
	}
	.h5main {
		width: 100%;
		min-width: 100%;
		.demo_top {
			height: 125px;
		}
		.demo_box1 {
			width: 100%;
			.box1_top {
				line-height: 88px;
				font-size: 26px;
				padding-left: 10px;
			}
			.box1_bottom {
				width: 100%;
				padding: 10px;
				height: auto;
				flex-direction: column;
				justify-content: flex-start;
				.bottom_left {
					width: auto;
					.left_title {
						font-size: 32px;
					}
					.left_time {
						font-size: 24px;
					}
					.left_con {
						font-size: 26px;
					}
					.left_tip {
						font-size: 24px;
					}
				}
				.bottom_right {
					width: auto;
					margin-top: 60px;
					.right_item {
						width: auto;
						max-height: 100%;
						.item_title {
							font-size: 26px;
							margin: 20px 0;
						}
						.item_item {
							.item_con {
								line-height: 66px;
								font-size: 26px;
							}
							.item_date {
								font-size: 24px;
							}
						}
					}
				}
			}
		}
	}
</style>
